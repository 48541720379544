import { CLEAR_APP_DATA, SETUP_APP, UPDATE_SETTINGS } from "../actions";
import { AppStore } from 'types';

const initialState: AppStore = {
  categories: [],
  postes: [],
  roles: [],
  structures: [],
  types: [],
  typesAbsences: [],
  comptoires: [],
  typesMenus: [],
  services: [],
  settings: {
    id: null,
    delai_annulation_reservation: 0,
    modal_show: false,
    created_at: "",
    updated_at: "",
  },
};

const appReducer = (state: AppStore = initialState, action) => {
  switch (action.type) {
    case SETUP_APP:
      return {
        ...state,
        ...action?.payload
      };

    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: action?.settings,
      };

    case CLEAR_APP_DATA:
      return initialState;

    default:
      return state;
  }
};

export default appReducer;