import { combineReducers, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import authReducer from './reducers/auth';
import appReducer from './reducers/app';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer
});

export default createStore(rootReducer, applyMiddleware(ReduxThunk));