import React, { useState, useEffect, useContext } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { Button, Pill, Checkbox, Input } from "components";
import { FaTimes } from "react-icons/fa";
import { GaleryItem } from 'types';
import TempImg from 'assets/images/login_bg.png';
import { Ellipsis } from 'react-awesome-spinners';
import { FiTrash2 } from 'react-icons/fi';
import { ReactComponent as GaleryIcon } from 'assets/icons/galery.svg';
import { toast } from 'react-toastify';
import { addImageToGalery, getGaleryItems, deleteGaleryItems } from "apis";

interface GaleryPickerProps {
  mode: 'reader' | 'picker';
  visible: boolean;
  onHide: (mode: 'reader' | 'picker', data?: GaleryItem[]) => void;
};

const GaleryPicker = (props: GaleryPickerProps) => {
  const classes = useStyles();

  const imageRef = React.useRef();

  const [images, setImages] = useState([]);

  const [pickedItems, setPickedItems] = useState([]);

  const handlePick = choice => {
    if (pickedItems?.findIndex(el => el?.id === choice?.id) === -1) {
      if (props.mode === "picker") {
        setPickedItems([choice]);
      } else {
        setPickedItems([...pickedItems, choice]);
      }
    } else {
      setPickedItems(pickedItems.filter(el => el?.id !== choice?.id));
    }
  };

  const handleValidateChoice = () => {
    props.onHide(props.mode, pickedItems);
    setPickedItems([]);
  };

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async (items = pickedItems) => {
    setDeleting(true);
    await deleteGaleryItems({
      id: items?.map(el => el?.id)
    }).then(response => {
      if (response.success) {
        handleFetchItems(false);
        toast.success("Images supprimées avec succès !");
      } else {
        toast.error(response?.message);
      }
    }).catch(err => {
      toast.error(err?.message);
    }).finally(() => {
      setDeleting(false);
    });
  };

  const [progress, setProgress] = useState(0);
  const [adding, setAdding] = useState(false);
  const handlePickFile = async e => {
    if (e?.target?.files[0]?.size > 2097152) {
      toast.error("Taille de fichier trop élevée (2 Mo maximum) !");
      return;
    }

    if (!!e?.target?.files[0]) {
      setAdding(true);
      const data = new FormData();
      data.append("photo", e.target.files[0]);

      setImages([{ id: 0, photo: e.target.files[0], uploading: true }, ...images]);

      await addImageToGalery(
        data,
        status => setProgress(status.loaded / status.total)
      ).then(response => {
        if (response.success) {
          toast.success("Image ajoutée avec succès !");
          handleFetchItems(false);
        } else {
          toast.error(response?.message);
        }
      }).catch(err => {
        toast.error(err?.message);
      }).finally(() => {
        setAdding(false);
        setProgress(0);
      });
    }
  };

  const handleClose = () => {
    setPickedItems([]);
    props.onHide(props.mode, []);
  };


  const [loading, setLoading] = useState(false);

  const handleFetchItems = React.useCallback(async (verbose = true) => {
    setLoading(verbose);
    await getGaleryItems().then(response => {
      if (response.success) {
        setImages(response?.data?.reverse());
      } else {
        toast.error(response?.message);
      }
    }).catch(err => {
      toast.error(err?.message);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    handleFetchItems();
  }, []);

  return (
    <Modal
      centered
      backdrop="static"
      size="lg"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={handleClose}
    >
      <input
        type="file"
        style={{ display: "none" }}
        ref={imageRef}
        accept=".png,.jpeg,.jpg"
        onChange={handlePickFile}
      />
      <CloseIcon onClick={handleClose} />
      <div className="h-100 w-100 d-flex justify-content-center flex-column p-3">
        <h3 className="text-principal font-weight-bold mb-4">Galerie</h3>
        <div className="w-100 mb-3 d-flex align-items-center justify-content-between">
          <div>Photos disponibles</div>
          <div className="d-flex align-items-center justify-content-end">
            {pickedItems.length > 0 &&
              <Pill variant={deleting ? "gray" : "red"} className="font-weight-bold border-10 mr-3" onClick={() => handleDelete()}>
                Supprimer
                <FiTrash2 size={20} className="ml-3" />
              </Pill>
            }
            <Pill
              className="font-weight-bold border-10"
              onClick={() => imageRef.current?.click()}
              variant={adding ? "gray" : "principal"}
            >
              Ajouter une image
              <GaleryIcon fill="#1F9D92" className="ml-3" />
            </Pill>
          </div>
        </div>
        <Container className="border-top border-bottom p-3 m-0 mx-auto">
          {
            loading ?
              <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                <Ellipsis color={"#1F9D92"} />
              </div>
              :
              images.map((el, k) => {
                const isPicked = pickedItems.findIndex(item => item?.id === el?.id) !== -1;
                return (
                  <Col key={k} className="p-3" md={3} lg={3} xs={12}>
                    <Image className={`shadow-sm border-5 ${isPicked ? 'active' : ''}`}>
                      <img src={el?.uploading ? URL.createObjectURL(el?.photo) : process.env.REACT_APP_STORAGE_URL + '/' + el?.photo} />
                      {el?.uploading && progress > 0 ?
                        <div className="indicator">
                          {parseInt(progress * 100)} %
                        </div>
                        :
                        <div className={`content`}>
                          <div className="w-100 d-flex align-items-center justify-content-around">
                            <Checkbox
                              checked={isPicked}
                              size={20}
                              onChange={handlePick.bind(this, el)}
                            />
                            <FiTrash2 color="#FFA79D" size={20} onClick={handleDelete.bind(this, [el])} />
                          </div>
                        </div>
                      }
                    </Image>
                  </Col>
                );
              })}
        </Container>
        {props.mode === 'picker' && pickedItems.length <= 1 &&
          <div className="mt-4 d-flex align-items-center justify-content-between w-100">
            <Input
              wrapperClassName="w-80"
              readOnly
              style={{ height: "35px", border: "0.5px solid #1F9D92" }}
              containerClassName="border-right-0"
              value={!!pickedItems[0]?.photo ? String(pickedItems[0]?.photo).split("/").pop() : "Veuillez choisir une image"}
            />
            <Button
              style={{ height: "35px" }}
              className="w-15 shadow-none rounded-0"
              variant={pickedItems.length === 0 ? 'gray' : 'principal'}
              onClick={pickedItems.length === 0 ? () => { } : handleValidateChoice}
            >
              Choisir
            </Button>
          </div>
        }
      </div>
    </Modal >
  );
};

GaleryPicker.defaultProps = {
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [GaleryPicker]"),
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    borderWidth: 0,
    width: "100%",
    padding: "20px"
  },
}));

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 20px;
  color: #CBD5E0;
  cursor: pointer;
`;

const Container = styled(Row)`
  height: 400px;
  overflow-y: auto;
  width: 100%;
`;

const Image = styled.div`
  height: 140px;
  width: 100%;
  backgroud-color: white;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 5px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 2;
    position: absolute;
  }

  .content {
    background-color: #0009;
    height: 0px;
    width: 100%;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }

  .indicator {
    height: 100%;
    width: 100%;
    font-size: 15px;
    background-color: #0009;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    z-index: 5;
  }

  &.active .content {
    height: 50px;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    padding: 5px;
    justify-content: center;
  }

  &:hover .content {
    height: 50px;
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    padding: 5px;
    justify-content: center;
  }
`;

export default GaleryPicker;