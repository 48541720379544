import { RESTAURATEUR_SIMPLE_ROLE } from 'configs';
import moment from 'moment';
import request from './request';

export const getPendingAccessControl = () => {
  return request({
    url: 'getPendingAccessControl',
    method: 'GET'
  });
};

/**
 * @param {String} status 1 = validate, 0 = reject
 * @param {String} motifRejet 
 */
export const respondPendingAccessControl = data => {
  return request({
    url: 'actionOnAskCancel',
    method: 'POST',
    data
  });
};

export const resetAccessControl = () => {
  return request({
    url: 'allowAccess',
    method: 'POST',
  });
};

/**
 * @param {Boolean} perCategory 
 */
export const getTypesStats = (perCategory = false) => {
  return request({
    url: perCategory === true ? `countUsager?categorie=${perCategory}` : `countUsager`,
    method: 'GET'
  });
};

/**
 * @param {Object} data name, email, codeRole, url
 */
export const createNewAdmin = data => {
  return request({
    url: 'createAgent',
    method: 'POST',
    data
  });
};

export const switchAdminStatus = data => {
  return request({
    url: 'userStatus',
    method: 'POST',
    data
  });
};

export const getAllAgents = (page = 1, limit = 10, name = "") => {
  return request({
    url: `getAgent?page=${page}&limit=${limit}&name=${name}`,
    method: 'GET'
  });
};

export const getUserLogs = (page = 1, limit = 10, name = "", dateDebut = "", dateFin = "") => {
  return request({
    url: `getLogs?page=${page}&limit=${limit}&name=${name}&dateDebut=${dateDebut}&dateFin=${dateFin}`,
    method: 'GET'
  });
};

export const searchAgent = (name = "") => {
  return request({
    url: `getAgent?name=${name}&limit=20`,
    method: 'GET'
  });
};

export const searchRestaurateurs = (name = "", limit = 20) => {
  return request({
    url: `getAgent?name=${name}&limit=${limit}&role=${RESTAURATEUR_SIMPLE_ROLE}`,
    method: 'GET'
  });
};

export const createNewUsager = data => {
  return request({
    url: 'createUsager',
    method: 'POST',
    data
  });
};

export const getAllUsagers = (page = 1, name = "", categorie = "", access_cantine = "", absence = 0, limit = 10) => {
  return request({
    url: `getUsagers?page=${page}&limit=${limit}&name=${name}&categorie=${categorie}&access_cantine=${access_cantine}&absence=${absence}`,
    method: 'GET'
  });
};

export const importUsagers = (data, handleProgress = () => { }) => {
  return request({
    url: `importUsagers`,
    method: 'POST',
    timeout: null,
    data,
    onUploadProgress: handleProgress
  });
};

export const searchUsagers = (search = "") => {
  return request({
    url: `getUsagerAutocomplete?search=${search}`,
    method: 'GET'
  });
};

export const updateUsager = data => {
  return request({
    url: 'updateUsager',
    method: 'POST',
    data
  });
};

export const updateUsagerAccess = data => {
  return request({
    url: 'updateAccesCantine',
    method: 'POST',
    data
  });
};

export const getUserAbsence = id => {
  return request({
    url: `absence/${id}`,
    method: 'GET',
  });
};

export const declareAbsence = data => {
  return request({
    url: 'createAbsence',
    method: 'POST',
    data
  });
};

export const updateAbsence = data => {
  return request({
    url: 'updateAbsence',
    method: 'POST',
    data
  });
};

export const deleteAbsence = (id, data) => {
  return request({
    url: `deleteAbsence/${id}`,
    method: 'DELETE',
    data
  });
};

// GALERY
export const getGaleryItems = () => {
  return request({
    url: `getGalerie`,
    method: 'GET',
  });
};

export const addImageToGalery = (data, handleProgress = () => { }) => {
  return request({
    url: `addImage`,
    method: 'POST',
    timeout: null,
    data,
    onUploadProgress: handleProgress
  });
};

export const deleteGaleryItems = (data) => {
  return request({
    url: `deleteImages`,
    method: 'POST',
    data
  });
};

export const createNewRepas = data => {
  return request({
    url: 'createRepas',
    method: 'POST',
    data
  });
};

export const searchRepas = (search = "") => {
  return request({
    url: `repasAuto?search=${search}`,
    method: 'GET'
  });
};

export const getAllRepas = () => {
  return request({
    url: `repasAuto?limit=${100}`,
    method: 'GET'
  });
};

export const createNewMenu = data => {
  return request({
    url: 'createMenu',
    method: 'POST',
    data
  });
};

export const updateMenu = data => {
  return request({
    url: 'editMenu',
    method: 'POST',
    data
  });
};

export const getMenuOfAWeek = (date = "", strict = false) => {
  return request({
    url: `getMenuOfWeek?date=${date}&strict=${strict === true ? 1 : 0}`,
    method: 'GET'
  });
};

export const getMenuOfADay = (date = "", strict = false) => {
  return request({
    url: `getMenuOfDay?date=${!!date ? date : moment().format("DD-MM-YYYY")}&strict=${strict === true ? 1 : 0}`,
    method: 'GET'
  });
};

export const searchMenu = () => {
  return request({
    url: `getMenuOfDay?date=${moment().format("DD-MM-YYYY")}`,
    method: 'GET'
  });
};

export const checkDeletableMenuRepas = data => {
  return request({
    url: 'checkMenu',
    method: 'POST',
    data
  });
};

export const deleteMenuRepas = data => {
  return request({
    url: 'deleteMenu',
    method: 'DELETE',
    data
  });
};

export const getCantineStatus = () => {
  return request({
    url: 'getCantineStatus',
    method: 'GET'
  });
};

export const alertUsagerForMenu = data => {
  return request({
    url: 'sendMenuMailNotif',
    method: 'POST',
    data
  });
};

export const openCantine = data => {
  return request({
    url: 'openComptoir',
    method: 'POST',
    data
  });
};

export const closeCantine = () => {
  return request({
    url: 'closeComptoir',
    method: 'POST',
  });
};

export const getConsommations = (page = 1, name = "", date = "", limit = 10) => {
  return request({
    url: `getConsommationlist?page=${page}&limit=${limit}&name=${name}&date=${date}`,
    method: 'GET',
  });
};

export const performBadge = (data) => {
  return request({
    url: 'doBadge',
    method: 'POST',
    data
  });
};

export const deleteBadgedUsager = id => {
  return request({
    url: `deletebadge/${id}`,
    method: 'DELETE',
  });
};

export const getReservations = (page = 1, name = "", dateDebut = "", dateFin = "", repasId = "", limit = 10) => {
  return request({
    url: `getReservations?page=${page}&limit=${limit}&name=${name}&dateDebut=${dateDebut}&dateFin=${dateFin}&repas_id=${repasId}`,
    method: 'GET',
  });
};

export const getProgrammations = (page = 1, dateDebut = "", dateFin = "", limit = 10) => {
  return request({
    url: `getComptoirProgram?page=${page}&limit=${limit}&dateDebut=${dateDebut}&dateFin=${dateFin}`,
    method: 'GET',
  });
};

export const updateProgram = (data) => {
  return request({
    url: `updateComptoirProgram`,
    method: "POST",
    data,
  });
};

export const deleteProgram = (id) => {
  return request({
    url: `deleteComptoirProgram/${id}`,
    method: 'DELETE',
  });
};

export const getBadgedUsers = (comptoirId, page = 1, name = "", limit = 10) => {
  return request({
    url: `getBadgeList/${comptoirId}?page=${page}&limit=${limit}&name=${name}`,
    method: 'GET',
  });
};

export const getAccesControlStatus = () => {
  return request({
    url: 'getCurrentAccessControl',
    method: 'GET',
  });
};

export const askToCancelControl = (data) => {
  return request({
    url: 'askCancelControl',
    method: 'POST',
    data
  });
};

export const createReservation = (menuRepasId) => {
  return request({
    url: 'makeReservation',
    method: 'POST',
    data: { menuRepas_id: menuRepasId }
  });
};

export const getMyReservations = (page = 1, limit = 10) => {
  return request({
    url: `getMyReservation?page=${page}&limit=${limit}`,
    method: 'GET',
  });
};

export const getAccessControlList = () => {
  return request({
    url: `getAccesContols`,
    method: 'GET',
  });
};

export const shareMenu = data => {
  return request({
    url: 'sendMenuMailNotif',
    method: 'POST',
    data,
  });
};

export const cancelMyReservation = (id) => {
  return request({
    url: `deleteReservation/${id}`,
    method: 'DELETE'
  });
};

export const getMyHistory = (page = 1, dateDebut = "", dateFin = "", limit = 5) => {
  return request({
    url: `getMyConsommationList?page=${page}&limit=${limit}&dateDebut=${dateDebut}&dateFin=${dateFin}`,
    method: 'GET'
  });
};

export const getStatsPeriode = (dateDebut = "", dateFin = "") => {
  return request({
    url: `getConsommationByPeriode?dateDebut=${dateDebut}&dateFin=${dateFin}`,
    method: 'GET'
  });
};

export const getStatsPeriodeCategory = (dateDebut = "", dateFin = "", categoryId = "") => {
  return request({
    url: `getConsommationByPeriode?dateDebut=${dateDebut}&dateFin=${dateFin}&categorie_id=${categoryId}`,
    method: 'GET'
  });
};

export const getStatsMensuelle = (year = "", month = "") => {
  return request({
    url: `getConsommationByMonth?month=${month}&year=${year}`,
    method: 'GET'
  });
};

export const getStatsAnnuelle = (year = "") => {
  return request({
    url: `getConsommationByYear?year=${year}`,
    method: 'GET'
  });
};

export const getStatsComptoirs = (dateDebut = "", dateFin = "") => {
  return request({
    url: `getConsommationByCompotoir?dateDebut=${dateDebut}&dateFin=${dateFin}`,
    method: 'GET'
  });
};