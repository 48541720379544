import React, { useState } from "react";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import { Ellipsis } from "react-awesome-spinners";
import "./styles.scss";
import { themeConfigs } from "configs";
import { Select } from "components/inputs";

const formatOptions = (props) => {
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const liStyle = {};
    const aStyle = {};
    if (active) {
      liStyle.color = "#1B968B";
      liStyle.border = "1px solid #1B968BAA";
      aStyle.color = "#1B968B";
    } else {
      liStyle.backgroundColor = "white";
      aStyle.color = "#212B36";
      liStyle.border = "1px solid #DFE3E8";
    }
    if (typeof page === "string") {
      liStyle.backgroundColor = "#919EAB";
      liStyle.border = "0px";
      aStyle.color = "white";
    }
    return (
      <CustomLi style={liStyle} onClick={handleClick}>
        <CustomA href="#" style={aStyle}>
          {page}
        </CustomA>
      </CustomLi>
    );
  };

  return {
    pageButtonRenderer,
    ...props,
  };
};

const Table = ({
  remote,
  data,
  keyField,
  page,
  sizePerPage,
  showSizeChanger,
  onSizeChange,
  onTableChange,
  totalSize,
  columns,
  bordered,
  headerClasses,
  headerWrapperClasses,
  rowStyle,
  rowClasses,
  striped,
  noPagination,
}) => {
  const [isLoading, setLoadingState] = React.useState(true);

  const [perPage, setPerPage] = useState(10);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
      setPerPage(sizePerPage || 10);
    }, 0);
  }, [sizePerPage]);

  const handlePerPageChange = e => {
    setPerPage(parseInt(e?.target?.value));
    onSizeChange(parseInt(e?.target?.value));
  };

  return (
    <div className="w-100 pb-5">
      {isLoading ?
        <div className="d-flex justify-content-center align-items-center">
          <Ellipsis color={themeConfigs.light.colors.principal} />
        </div>
        :
        <PaginationProvider
          pagination={paginationFactory(
            formatOptions({
              custom: true,
              page,
              sizePerPage,
              totalSize,
            })
          )}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote={remote}
                keyField={keyField || "id"}
                bordered={bordered}
                striped={striped}
                data={data}
                columns={columns}
                headerWrapperClasses={`bg-principal-pills shadow-sm border-none ${headerWrapperClasses}`}
                headerClasses={`border-none ${headerClasses}`}
                rowClasses={`chs-table-row font-90 shadow-sm ${rowClasses}`}
                rowStyle={{ overflow: "hidden", ...rowStyle }}
                {...(!!onTableChange ? { onTableChange } : null)}
                {...paginationTableProps}
              />
              {
                !(data && Array.isArray(data) && data.length > 0) && <div className="text-center w-100 my-4">Aucune donnée !</div>
              }
              {
                !!noPagination ?
                  null :
                  <div className="d-flex align-items-end justify-content-between mt-4 text-gray">
                    <span>Affichage de {totalSize > 0 ? (page - 1) * sizePerPage + 1 : 0} à {totalSize < (page * sizePerPage) ? totalSize : page * sizePerPage} sur un total de {totalSize}</span>
                    {showSizeChanger &&
                      <div className="d-flex align-items-center">
                        <Select
                          wrapperStyle={{ width: "80px" }}
                          containerStyle={{ height: "35px" }}
                          containerClassName="border-5"
                          wrapperClassName="mr-3"
                          value={String(perPage)}
                          onChange={handlePerPageChange}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                          <option value="30">30</option>
                          <option value="35">35</option>
                          <option value="40">40</option>
                          <option value="45">45</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </Select>
                        élements par page
                      </div>
                    }
                    <PaginationListStandalone {...paginationProps} />
                  </div>
              }
            </div>
          )}
        </PaginationProvider>
      }
    </div>
  );
};

Table.defaultProps = {
  onSizeChange: () => { },
  onTableChange: () => { },
  showSizeChanger: false,
};

export default Table;

const CustomLi = styled.li`
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
`;

const CustomA = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
