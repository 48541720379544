import React, { Suspense, lazy, useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { LayoutConsumer } from "layouts/Layout";
import { DualRing } from 'react-awesome-spinners';
import {
  themeConfigs,
  routes,
  ADMIN_FONCTIONNEL_ROLE,
  ADMIN_RESTAURATEUR_ROLE,
  ADMIN_TECHNIQUE_ROLE,
  RESTAURATEUR_SIMPLE_ROLE,
  USER_ROLE
} from 'configs';
import { authenticate, setupApp, useAuth } from "contexts/redux";
import { useDispatch } from "react-redux";
import { isEnabled } from "helpers";

const Login = lazy(() => import('views/auth/Login'));
const ResetPasswordInit = lazy(() => import('views/auth/ResetPasswordInit'));
const ResetPasswordChange = lazy(() => import('views/auth/ResetPasswordChange'));
const Profile = lazy(() => import('views/auth/Profile'));
const ChangePassword = lazy(() => import('views/auth/ChangePassword'));

const Error = lazy(() => import('views/error'));
const Unauthorized = lazy(() => import('views/error/Unauthorized'));

const DashboardFonctionnel = lazy(() => import('views/admins/fonctionnel/Dashboard'));
const GestUsers = lazy(() => import('views/admins/fonctionnel/GestUsers'));
const AddUsager = lazy(() => import('views/admins/fonctionnel/AddUsager'));
const EditUsager = lazy(() => import('views/admins/fonctionnel/EditUsager'));
const FonctionnelMenuRestaurant = lazy(() => import('views/admins/fonctionnel/MenuRestaurant'));

const DashboardRestaurateur = lazy(() => import('views/admins/restaurateur/Dashboard'));
const RestaurateurMenuRestaurant = lazy(() => import('views/admins/restaurateur/MenuRestaurant'));
const Reservations = lazy(() => import('views/admins/restaurateur/Reservations'));
const GestionCantine = lazy(() => import('views/admins/restaurateur/GestionCantine'));

const DashboardTechnique = lazy(() => import('views/admins/technique/Dashboard'));
const GestionComptes = lazy(() => import('views/admins/technique/GestionComptes'));
const Historiques = lazy(() => import('views/admins/technique/Historiques'));
const TechniqueMenuRestaurant = lazy(() => import('views/admins/fonctionnel/MenuRestaurant'));
const Informations = lazy(() => import('views/admins/technique/Informations'));
const Settings = lazy(() => import('views/admins/technique/Settings'));
const GestionLogs = lazy(() => import('views/admins/technique/GestionLogs'));
const Statistiques = lazy(() => import('views/admins/technique/Statistiques'));

const DashboardRestaurateurSimple = lazy(() => import('views/restaurateur-simple/Dashboard'));
const RestaurateurSimpleMenuRestaurant = lazy(() => import('views/restaurateur-simple/MenuRestaurant'));

const UserHome = lazy(() => import('views/usager/Home'));
const UserMesReservations = lazy(() => import('views/usager/MesReservations'));
const UserMonHistorique = lazy(() => import('views/usager/MonHistorique'));


const AppRoute = ({
  component: Component,
  path,
  fullLayout,
  adminLayout,
  roles,
  location,
  ...rest
}) => {
  const currentUser = useAuth();

  if (!!currentUser?.id) {
    if (currentUser?.default_password_change === false) {
      return <Redirect to={routes["CHANGE.PASSWORD"].path} />;
    }

    if (isEnabled(roles, currentUser?.codeRole)) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <LayoutConsumer>
              {({
                FullLayout,
                DefaultLayout,
                AdminLayout,
                activeTheme: { colors },
                ...rest
              }) => {
                const LayoutComponent = fullLayout ?
                  FullLayout
                  :
                  adminLayout ?
                    AdminLayout
                    :
                    DefaultLayout;
                return (
                  <LayoutComponent {...props}>
                    <Suspense
                      fallback={
                        <div
                          style={{
                            height: "100vh",
                            width: "100%",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center"
                          }}
                        >
                          <DualRing size={40} color={colors.principal} />
                        </div>
                      }
                    >
                      <Component {...props} />
                    </Suspense>
                  </LayoutComponent>
                );
              }}
            </LayoutConsumer>
          )}
        />
      );
    } else {
      return <Redirect to={routes["UNAUTHORIZED"].path} />;
    }
  } else {
    if (location?.pathname !== routes["AUTH.LOGIN"].path) {
      return <Redirect to={routes["AUTH.LOGIN"].path} />;
    }
  }
};

const AppRouter = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const currentUser = useAuth();

  const tryAutoAuth = async () => {
    setLoading(true);
    dispatch(authenticate(response => {
      if (response?.success) {
        dispatch(setupApp(() => {
          setLoading(false);
        }));
      } else {
        setLoading(false);
      }
    }));
  };

  useEffect(() => {
    tryAutoAuth();
  }, []);

  if (loading) {
    return (
      <div style={{ height: '95vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <DualRing color={themeConfigs.light.colors.principal} />
      </div>
    );
  } else {
    return (
      <BrowserRouter basename={routes['ADMIN.DASHBOARD'].path}>
        <Switch>
          <Route
            exact
            path={routes["CHANGE.PASSWORD"].path}
            component={ChangePassword}
          />
          <Route
            exact
            path={routes["AUTH.LOGIN"].path}
            component={Login}
          />
          <Route
            exact
            path={routes["AUTH.RESET.PASSWORD.INIT"].path}
            component={ResetPasswordInit}
          />
          <Route
            exact
            path={routes["AUTH.RESET.PASSWORD.CHANGE"].path}
            component={ResetPasswordChange}
          />
          <Route
            exact
            path={routes["ERROR"].path}
            component={Error}
          />
          <Route
            exact
            path={routes["UNAUTHORIZED"].path}
            component={Unauthorized}
          />
          <AppRoute
            exact
            path={routes['PROFILE'].path}
            roles={routes['PROFILE'].roles}
            component={Profile}
            adminLayout={currentUser?.codeRole !== USER_ROLE}
          />
          <AppRoute
            exact
            path={routes['ADMIN.DASHBOARD'].path}
            roles={routes['ADMIN.DASHBOARD'].roles}
            component={
              currentUser?.codeRole === ADMIN_FONCTIONNEL_ROLE ?
                DashboardFonctionnel
                :
                currentUser?.codeRole === ADMIN_RESTAURATEUR_ROLE ?
                  DashboardRestaurateur
                  :
                  currentUser?.codeRole === ADMIN_TECHNIQUE_ROLE ?
                    DashboardTechnique :
                    currentUser?.codeRole === RESTAURATEUR_SIMPLE_ROLE ?
                      DashboardRestaurateurSimple
                      :
                      currentUser?.codeRole === USER_ROLE ?
                        UserHome
                        :
                        Unauthorized
            }
            adminLayout={currentUser?.codeRole !== USER_ROLE}
          />
          <AppRoute
            exact
            path={routes['ADMIN.GEST.USERS'].path}
            roles={routes['ADMIN.GEST.USERS'].roles}
            component={GestUsers}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.ADD.USERS'].path}
            roles={routes['ADMIN.ADD.USERS'].roles}
            component={AddUsager}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.EDIT.USERS'].path}
            roles={routes['ADMIN.EDIT.USERS'].roles}
            component={EditUsager}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.RESTAURATEUR.MENU'].path}
            roles={routes['ADMIN.RESTAURATEUR.MENU'].roles}
            component={
              currentUser?.codeRole === ADMIN_FONCTIONNEL_ROLE ?
                FonctionnelMenuRestaurant
                :
                currentUser?.codeRole === ADMIN_RESTAURATEUR_ROLE ?
                  RestaurateurMenuRestaurant
                  :
                  currentUser?.codeRole === RESTAURATEUR_SIMPLE_ROLE ?
                    RestaurateurSimpleMenuRestaurant
                    :
                    currentUser?.codeRole === ADMIN_TECHNIQUE_ROLE ?
                      TechniqueMenuRestaurant
                      :
                      Unauthorized
            }
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.RESTAURATEUR.RESERVATIONS'].path}
            roles={routes['ADMIN.RESTAURATEUR.RESERVATIONS'].roles}
            component={Reservations}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.RESTAURATEUR.CANTINE'].path}
            roles={routes['ADMIN.RESTAURATEUR.CANTINE'].roles}
            component={GestionCantine}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.TECHNIQUE.GEST.COMPTES'].path}
            roles={routes['ADMIN.TECHNIQUE.GEST.COMPTES'].roles}
            component={GestionComptes}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.TECHNIQUE.HISTORIQUES'].path}
            roles={routes['ADMIN.TECHNIQUE.HISTORIQUES'].roles}
            component={Historiques}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.TECHNIQUE.INFOS'].path}
            roles={routes['ADMIN.TECHNIQUE.INFOS'].roles}
            component={Informations}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.TECHNIQUE.SETTINGS'].path}
            roles={routes['ADMIN.TECHNIQUE.SETTINGS'].roles}
            component={Settings}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.LOGS'].path}
            roles={routes['ADMIN.LOGS'].roles}
            component={GestionLogs}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.STATS'].path}
            roles={routes['ADMIN.STATS'].roles}
            component={Statistiques}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['ADMIN.MY.RESERVATIONS'].path}
            roles={routes['ADMIN.MY.RESERVATIONS'].roles}
            component={UserMesReservations}
            adminLayout
          />
          <AppRoute
            exact
            path={routes['USER.MY.HISTORY'].path}
            roles={routes['USER.MY.HISTORY'].roles}
            component={UserMonHistorique}
          />
          <AppRoute
            exact
            path={routes['USER.MY.RESERVATIONS'].path}
            roles={routes['USER.MY.RESERVATIONS'].roles}
            component={UserMesReservations}
          />
        </Switch>
      </BrowserRouter>
    );
  }
};

export default AppRouter;