import React from 'react';
import styled from "styled-components";
import { SearchInput, Button } from "components";
import BgImg from "./bg.png";

const HeaderCard = () => {
  return (
    <Content>
      <div className="h-100 d-flex flex-column justify-content-center">
        <Title>Order food anytime and anymore</Title>
        <p className="mt-3" style={{ width: "300px" }}>Delicious food are waiting for you, we are always near to you</p>
        <div className="d-none d-lg-flex">
          <SearchInput
            wrapperStyle={{ width: "400px" }}
            wrapperClassName="mt-4"
            containerClassName="border-0 border-20 bg-white p-2"
            placeholder="Type a food name here"
            iconRight={
              <Button
                variant="infos"
                value="Search"
                className="font-80 px-3 border-20"
              />
            }
          />
        </div>
      </div>
      <Image src={BgImg} />
    </Content>
  );
};

const Content = styled.div`
  height: 400px;
  width: 100%;
  border-radius: 20px;
  background-color: #9ACD321A;
  padding: 50px 0px 50px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const Title = styled.h5`
  font-family: Ephesis !important;
  width: 300px;
  font-size: 40px;
`;

const Image = styled.img`
  width: 700px;
  height: 400px;
  object-fit: contain;
  transform: translate(150px, 20px);
`;

export default HeaderCard;