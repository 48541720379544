import { ADMIN_FONCTIONNEL_ROLE, ADMIN_RESTAURATEUR_ROLE, ADMIN_TECHNIQUE_ROLE, RESTAURATEUR_SIMPLE_ROLE, USER_ROLE } from "./data";

export const routes = {
	'AUTH.LOGIN': {
		path: '/connect',
		roles: []
	},
	'AUTH.RESET.PASSWORD.INIT': {
		path: '/password-recorvery',
		roles: []
	},
	'AUTH.RESET.PASSWORD.CHANGE': {
		path: '/reset-change-password',
		roles: []
	},
	'CHANGE.PASSWORD': {
		path: '/modifier-mot-de-passe',
		roles: []
	},
	'ERROR': {
		path: '/error',
		roles: []
	},
	'UNAUTHORIZED': {
		path: '/not-authorized',
		roles: []
	},
	'ADMIN.DASHBOARD': {
		path: '/',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_RESTAURATEUR_ROLE, ADMIN_TECHNIQUE_ROLE, RESTAURATEUR_SIMPLE_ROLE, USER_ROLE],
	},
	'ADMIN.GEST.USERS': {
		path: '/users-managment',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.ADD.USERS': {
		path: '/users-managment/ajouter',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.EDIT.USERS': {
		path: '/users-managment/modifier',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.RESTAURATEUR.MENU': {
		path: '/menu-restaurant',
		roles: [ADMIN_RESTAURATEUR_ROLE, ADMIN_TECHNIQUE_ROLE, ADMIN_FONCTIONNEL_ROLE, RESTAURATEUR_SIMPLE_ROLE],
	},
	'ADMIN.RESTAURATEUR.RESERVATIONS': {
		path: '/reservations',
		roles: [ADMIN_RESTAURATEUR_ROLE],
	},
	'ADMIN.RESTAURATEUR.CANTINE': {
		path: '/gestion-cantine',
		roles: [ADMIN_RESTAURATEUR_ROLE],
	},
	'ADMIN.TECHNIQUE.GEST.USAGERS': {
		path: '/gest-users',
		roles: [ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.TECHNIQUE.GEST.COMPTES': {
		path: '/gest-comptes',
		roles: [ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.TECHNIQUE.HISTORIQUES': {
		path: '/history',
		roles: [ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.TECHNIQUE.INFOS': {
		path: '/infos-utiles',
		roles: [ADMIN_TECHNIQUE_ROLE, ADMIN_RESTAURATEUR_ROLE],
	},
	'ADMIN.TECHNIQUE.SETTINGS': {
		path: '/parametres',
		roles: [ADMIN_TECHNIQUE_ROLE],
	},
	'ADMIN.MY.RESERVATIONS': {
		path: '/admin/mes-reservations',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_TECHNIQUE_ROLE]
	},
	'ADMIN.LOGS': {
		path: '/gestion-des-logs',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_TECHNIQUE_ROLE]
	},
	'ADMIN.STATS': {
		path: '/statistiques',
		roles: [ADMIN_FONCTIONNEL_ROLE, ADMIN_TECHNIQUE_ROLE]
	},
	'PROFILE': {
		path: '/profile',
		roles: []
	},
	'USER.MY.RESERVATIONS': {
		path: '/mes-reservations',
		roles: [USER_ROLE]
	},
	'USER.MY.HISTORY': {
		path: '/mon-historique',
		roles: [USER_ROLE]
	},
};