import React from "react";

interface SwitchTextProps {
  rightLabel: string | React.ReactNode;
  leftLabel: string | React.ReactNode;
  onChange: (side: 'left' | 'right') => void;
  value: 'left' | 'right';
  variant: "principal" | "green" | "infos" | "red" | "blue" | "black" | "gray";
  size: 'sm' | 'lg' | 'md';
};

const SwitchText = (props: SwitchTextProps) => {
  const handleClick = (side) => {
    props.onChange(side);
  };

  return (
    <div
      {...props}
      className={`
        shadow-sm
        chs-switch_text
        ${props.value === 'right' ? 'active' : ''}
        chs-switch_text-${props.size ? props.size : 'md'}
        chs-switch_text-${props.variant ? props.variant : ''}
        ${props.className ? props.className : ''}
      `}
    >
      <div
        onClick={handleClick.bind(this, 'left')}
        className="chs-switch_text-option chs-switch_text-option_left"
      >{props?.leftLabel || "Non"}</div>
      <div
        onClick={handleClick.bind(this, 'right')}
        className="chs-switch_text-option chs-switch_text-option_right"
      >{props?.rightLabel || "Oui"}</div>
    </div>
  );
};

SwitchText.defaultProps = {
  onChange: () => console.log("Please attach an onHide function to [SwitchText]"),
  value: false,
  className: ""
};

export default SwitchText;