import React from 'react';
import { Popover, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import PropTypes from "prop-types";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { BsDot } from 'react-icons/bs';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const CalendarPopover = props => {
  const classes = useStyles();

  const handlePickDate = date => {
    props.onDateChange(date);
    props.onHide();
  };

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide.bind(this, null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      <div className="d-flex flex-column w-100">
        <Calendar
          className={"mt-4 border-0 w-100"}
          onChange={handlePickDate}
          value={props.date}
          tileClassName={({ activeStartDate, date, view }) => {
            return date?.getTime() === props?.date?.getTime() ? "active" : "";
          }}
          view="month"
          nextLabel={<FaAngleRight size={20} />}
          next2Label={<FaAngleDoubleRight size={20} />}
          prevLabel={<FaAngleLeft size={20} />}
          prev2Label={<FaAngleDoubleLeft size={20} />}
          locale="fr"        />
      </div>
    </Popover>
  );
};

CalendarPopover.propTypes = {
  visible: PropTypes.bool,
  anchor: PropTypes.any,
  onHide: PropTypes.func,
  onChange: PropTypes.func,
  date: PropTypes.object,
  onDateChange: PropTypes.func,
};

CalendarPopover.defaultProps = {
  visible: false,
  onChange: () => console.log("No onChange method given"),
  onHide: () => console.log("No onHide method given"),
  date: new Date(),
  onDateChange: () => { }
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: '98vw',
    borderRadius: "5px",
    marginTop: "5px",
    padding: "10px",
    paddingTop: 0,
    boxShadow: "0px 5px 10px 0px #0002"
  }
}));

const DayCell = styled.div`
  height: 20px;
`;

export default CalendarPopover;