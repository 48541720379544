import React from 'react';
import { Popover, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import PropTypes from "prop-types";

const StatusPopover = props => {
  const classes = useStyles();

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide.bind(this, null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      <div className="d-flex flex-column w-100">
        {props.elements?.map((el, k) => (
          <Option key={k} onClick={props.onChange.bind(this, el)}>
            {el?.libelle || ""}
          </Option>
        ))}
        <Option className="text-red" onClick={props.onChange.bind(this, null)}>
          Aucune
        </Option>
      </div>
    </Popover>
  );
};

StatusPopover.propTypes = {
  elements: PropTypes.array,
  visible: PropTypes.bool,
  anchor: PropTypes.any,
  onHide: PropTypes.func,
  onChange: PropTypes.func,
};

StatusPopover.defaultProps = {
  elements: [],
  visible: false,
  onChange: () => console.log("No onChange method given"),
  onHide: () => console.log("No onHide method given"),
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: '150px',
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "-2px -1px 10px 2px rgba(192, 197, 223, 0.2)"
  }
}));

const Option = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ddd8;
  }
`;

export default StatusPopover;