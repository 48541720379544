import React from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';
import styled from 'styled-components';
import PropTypes from "prop-types";

const OPER = {
	MINUS: "MINUS",
	PLUS: "PLUS"
};

const NumberPicker = ({
	min,
	max,
	value,
	onChange,
	cellClassName,
	cellStyle,
	wrapperStyle,
	wrapperClassName
}) => {
	const handleChange = operType => {
		if (operType === OPER.MINUS) {
			if (Number.isInteger(min)) {
				onChange((parseInt(value) - 1 >= min) ? parseInt(value) - 1 : min);
			} else {
				onChange(parseInt(value) - 1);
			}
		} else if (operType === OPER.PLUS) {
			if (Number.isInteger(max)) {
				onChange((parseInt(value) + 1 <= max) ? parseInt(value) + 1 : max);
			} else {
				onChange(parseInt(value) + 1);
			}
		}
	};

	return (
		<div
			style={{ width: "110px", ...wrapperStyle }}
			className={`d-flex align-items-center justify-content-center ${wrapperClassName || ""}`}
		>
			<Cell
				onClick={handleChange.bind(this, OPER.MINUS)}
				style={{ ...cellStyle, borderRadius: "5px 0px 0px 5px" }}
				className={`cursor-pointer border border-right-0 ${cellClassName || ""}`}
			>
				<BiMinus />
			</Cell>
			<Cell style={{ ...cellStyle }} className={`border ${cellClassName || ""}`}>
				{value || "0"}
			</Cell>
			<Cell
				onClick={handleChange.bind(this, OPER.PLUS)}
				style={{ ...cellStyle, borderRadius: "0px 5px 5px 0px" }}
				className={`cursor-pointer border border-left-0 ${cellClassName || ""}`}
			>
				<BiPlus />
			</Cell>
		</div>
	)
};

NumberPicker.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
};

NumberPicker.defaultProps = {
	min: 0,
	max: 100,
	onChange: () => console.log("no onChange method forwarded to NumberPicker")
};

const Cell = styled.span`
	height: 35px;
	width: 33.33%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #7C809E;
`;

export default NumberPicker;