export const themeConfigs = {
	light: {
		colors: {
			principal: "#1F9D92",
			infos: "#FFA500",
			danger: "#FB4E4E",
			gray: "#707070",
			background: "#FFFFFF",
			text: "#555",
			border: "#ddd"
		},
	},
	dark: {
		colors: {
			principal: "#1F9D92",
			infos: "#FFA500",
			danger: "#FB4E4E",
			gray: "#707070",
			background: "#FFFFFF",
			text: "#555",
			border: "#ddd"
		}
	}
};

export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';

export const FULL_LAYOUT = 'full';
export const DEFAULT_LAYOUT = 'default';
export const ADMIN_LAYOUT = "admin";

export const layoutConfigs = {
	full: FULL_LAYOUT,
	default: DEFAULT_LAYOUT,
	admin: ADMIN_LAYOUT,
};

export const CHART_COLORS = [
	"#F9C22D",
	"#146D65",
	"#4287f5",
	"#a6294d",
	"#70a629",
	"#50544b",
	"#5155cf",
	"#d13e36",
	"#b38d8b",
	"#9c7009"
];