import React from "react";
import PropTypes from 'prop-types';
import "./styles.scss";

const SwitchType2 = props => {
  const handleClick = () => {
    props.onChange(!props.checked);
  };

  return (
    <div
      className={`
        chs-switch
        chs-switch-type2
        ${props.checked ? 'active' : ''}
        chs-switch-${props.size ? props.size : 'md'}
        chs-switch-${props.variant ? props.variant : ''}
        ${props.className ? props.className : ''}
      `}
      onClick={handleClick}
    >
      <div className={`chs-switch-indicator-type2 ${props.indicatorClassName || ""}`}></div>
    </div>
  );
};

SwitchType2.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'md']),
  variant: PropTypes.oneOf(["principal", "green", "infos", "red", "blue", "black", "gray"]),
};

SwitchType2.defaultProps = {
  onChange: () => console.log("Please attach an onHide function to [SwitchType2]"),
  checked: false,
  variant: "principal",
  size: "md",
  className: ""
};

export default SwitchType2;