import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LayoutContext } from 'layouts/Layout';
import { Button, Pill, Textarea } from "components";
import { FaTimes } from "react-icons/fa";
import { toast } from 'react-toastify';


const ConfirmModal = props => {
  const classes = useStyles();
  const { width, activeTheme: { colors } } = useContext(LayoutContext);

  const [motif, setMotif] = useState("");

  const handleSubmit = () => {
    if (!!motif) {
      props.onHide(true, motif);
      setMotif("");
    } else {
      toast.warn("Motif requis !");
    }
  };

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-100 w-100 d-flex justify-content-center flex-column p-3">
        <h3 className="text-principal font-weight-bold mb-4">{props?.title || ""}</h3>
        <Textarea
          wrapperClassName="mt-3"
          label={"Motif"}
          labelClassName={"font-90"}
          containerClassName="border-5"
          placeholder="Texte"
          rows={3}
          value={motif}
          onChange={e => setMotif(e?.target?.value)}
        />
        <div className="d-flex align-items-center justify-content-end mt-5 w-100">
          <Button
            className={`px-5 border-5 w-100`}
            onClick={handleSubmit}
          >
            Valider
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmModal.defaultProps = {
  visible: false,
  title: "Confirmation",
  onHide: () => console.log("Please attach an onHide function to [ConfirmModal]"),
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    borderWidth: 0,
    width: "100%",
    padding: "20px"
  },
}));

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 20px;
  color: #CBD5E0;
  cursor: pointer;
`;


export default ConfirmModal;