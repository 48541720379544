import React from "react";
import "./styles.scss";
import styled from "styled-components";
import { routes } from "configs";
import { MdMenu } from 'react-icons/md';
import NavBar from './components/Navbar';
import SideBar from "./components/SideBar";


const Default = ({ children, ...rest }) => {
  const [showSidebar, setShowSidebar] = React.useState(false);

  const toggleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <Wrapper>
      <SideBar
        visible={showSidebar}
        handleToggle={toggleShowSidebar}
      />
      <div className="w-100 text-lg-center mb-5 position-relative d-flex align-items-center justify-content-lg-center">
        <a
          style={{
            ...(showSidebar ? { filter: "blur(0.5rem)" } : {})
          }}
          className="chs-nav-logo" href={routes["ADMIN.DASHBOARD"].path}
        >
          <h2 className="font-weight-bold text-white">DigiCantine</h2>
        </a>
        <MdMenu
          size={30}
          className="d-lg-none text-white"
          style={{ position: "absolute", right: 0, top: 0 }}
          onClick={toggleShowSidebar}
        />
      </div>
      <NavBar />
      <div
        className="w-100 bg-white"
        style={{
          minHeight: "80vh",
          ...(showSidebar ? { filter: "blur(0.5rem)" } : {})
        }}
      >
        {children}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: linear-gradient(180deg, #38A198 10%, #CEE8E0 90%);
  padding: 80px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    padding-top: 50px;
  }
`;

export default Default;