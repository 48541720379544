import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';
import { FaAngleDown } from 'react-icons/fa';

const Select = ({
  type,
  name,
  label,
  value,
  error,
  onChange,
  iconLeft,
  iconRight,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  children,
  ...rest
}) => {
  return (
    <div style={{ width: "100%", ...wrapperStyle }} className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}>
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div style={containerStyle} className={`chs-input ${containerClassName}`}>
        {iconLeft &&
          <div className="chs-input__left-icon">
            {iconLeft}
          </div>
        }
        <select
          name={name}
          value={value}
          onChange={onChange}
          {...rest}
          className={`chs-input__select ${className} ${iconLeft ? 'ml-0' : ''} ${iconRight ? 'mr-0' : ''}`}
        >{children}</select>
        {iconRight &&
          <div className="chs-input__right-icon">
            {iconRight}
          </div>
        }
      </div>
      {!!error && <div className={`chs-input__feedback ${errorClassName}`}>{error}</div>}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  iconLeft: PropTypes.any,
  iconRight: PropTypes.any,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  placeholder: PropTypes.string
};

Select.defaultProps = {
  name: '',
  value: '',
  onChange: () => console.log('no onChange method attached to Select...'),
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
  containerStyle: {},
  wrapperClassName: "",
  wrapperStyle: {},
};

export default Select;