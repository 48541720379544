import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LayoutContext } from 'layouts/Layout';
import { SwiftText, validate } from 'contexts/swift-inputs';

const ConfirmPasswordModal = props => {
  const classes = useStyles();
  const { width, activeTheme: { colors } } = useContext(LayoutContext);

  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const handleChange = e => {
    if (!!e && !!e.target) {
      setPassword(e.target.value);
      const fieldValidation = validate(
        { password: e.target.value },
        { password: "string|required|min:6|max:150" }
      );
      setErrorPassword(fieldValidation.response.password.error);
    }
  };

  const handleClose = (status, pswd = "") => {
    if (!status) {
      setErrorPassword("");
      setPassword("");
      props.onHide(status, pswd);
    } else {
      const fieldValidation = validate(
        { password: pswd },
        { password: "string|required|min:6|max:150" }
      );
      if (fieldValidation.isFormValid()) {
        setErrorPassword("");
        setPassword("");
        props.onHide(status, pswd);
      } else {
        setErrorPassword(fieldValidation?.response?.password?.error);
      }
    }
  };

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={handleClose.bind(this, false)}
    >
      <Close size={20} style={{ position: "absolute", right: "20px", top: "20px", cursor: "pointer" }} onClick={handleClose.bind(this, false)} />
      <div className="h-100 w-100 d-flex align-items-center justify-content-center flex-column p-4 mt-5">
        <span
          className={props.messageClassName}
          style={{
            ...props.messageStyle
          }}
        >{props.message}</span>
        <SwiftText
          name="title"
          type="password"
          label="Veuillez entre votre mot de passe"
          labelStyle={{ color: "black", fontSize: "14px", }}
          value={!!password ? password : ""}
          error={!!errorPassword ? errorPassword : ""}
          onChange={handleChange}
          containerClassName='w-80 p-0 my-4'
          className="rounded-0"
          theme={colors}
          errorStyle={{ fontSize: "12px", color: colors.principal }}
        />
        <div className="d-flex align-items-center my-4 w-100" style={{ justifyContent: "space-evenly" }}>
          <Button
            className={props.cancelClassName}
            backgroundColor={`${colors.danger}`}
            color={"white"}
            style={{
              textTransform: "uppercase",
              ...props.successStyle
            }}
            onClick={handleClose.bind(this, false)}
          >
            {props.cancelLabel}
          </Button>
          <Button
            className={props.successClassName}
            backgroundColor={colors.principal}
            color={"white"}
            style={{
              textTransform: "uppercase",
              ...props.successStyle
            }}
            onClick={handleClose.bind(this, true, password)}
          >
            {props.successLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmPasswordModal.propTypes = {
  successLabel: PropTypes.string,
  successStyle: PropTypes.object,
  successClassName: PropTypes.string,
  cancelStyle: PropTypes.object,
  cancelLabel: PropTypes.string,
  cancelClassName: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  messageClassName: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmPasswordModal.defaultProps = {
  visible: false,
  message: "Voulez-vous vraiment continuer ?",
  successLabel: "Oui",
  cancelLabel: "Non",
  cancelStyle: {},
  successStyle: {},
  messageStyle: {},
  onHide: () => console.log("Please attach an onHide function to [ConfirmPasswordModal]"),
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    width: "100%",
  },
}));

const Button = styled.div`
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: ${({ color }) => !!color ? color : "white"};
  background-color: ${({ backgroundColor }) => !!backgroundColor ? backgroundColor : "#34CE80"};
  &:hover {
    border-radius: 3px;
    box-shadow: ${({ backgroundColor }) => !!backgroundColor && !['#fff', "#FFF", "#FFFFFF", "#ffffff", "white"].includes(backgroundColor) ? `0px 0px 1px 3px ${backgroundColor}40` : "0px 0px 1px 3px #34CE8005"};
  }
`;

export default ConfirmPasswordModal;