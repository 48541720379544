import { AUTHENTICATE, LOGOUT } from "../actions";
import { AuthType } from 'types';

const initialState: AuthType = {
	id: null,
	name: '',
	email: '',
	photo: null,
	matricule: '',
	profil: '',
	codeRole: '',
	codeBadge: '',
	status: null,
	motif_access_denied: '',
	access_cantine: null,
	nbr_repas: null,
	email_verified_at: null,
	default_password_change: null,
	poste_id: null,
	type_id: null,
	structure_id: null,
	categorie_id: null,
	created_by: null,
	deleted_by: null,
	updated_by: null,
	created_at: null,
	updated_at: null,
	deleted_at: null,
};

const userReducer = (state: AuthStore = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return {
				...state,
				...action.user
			}

		case LOGOUT:
			return initialState;

		default:
			return state;
	}
};

export default userReducer;