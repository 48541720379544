import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, useAuth } from 'contexts/redux';
import classnames from "classnames";
import { FaTimes } from 'react-icons/fa';
import { routes } from 'configs';
import { Pill } from 'components';


const SideBar = ({ visible, handleToggle, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useAuth();

  const isCurrent = url => {
    return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const goTo = route => {
    handleToggle();
    history.push(route);
  };

  return (
    <div
      className={classnames("chs-admin-sidebar d-lg-none", visible ? "active" : "")}
    >
      <FaTimes
        style={{
          position: 'absolute',
          top: "15px",
          right: "15px",
        }}
        size={20}
        onClick={handleToggle}
        className="chs-admin-sidebar__brand"
      />
      <a className="chs-nav-logo" href={routes["ADMIN.DASHBOARD"].path}>
        <h3 className="font-weight-bold text-principal my-5 px-5">DigiCantine</h3>
      </a>
      <ul className="w-100 d-flex flex-column px-5">
        <CustomLi
          onClick={goTo.bind(this, routes["ADMIN.DASHBOARD"].path)}
          className={`w-100 py-3 ${isCurrent(routes["ADMIN.DASHBOARD"].path) ? 'active' : ''}`}
        >
          Menu
        </CustomLi>
        <CustomLi
          onClick={goTo.bind(this, routes["USER.MY.RESERVATIONS"].path)}
          className={`w-100 py-3 ${isCurrent(routes["USER.MY.RESERVATIONS"].path) ? 'active' : ''}`}
        >
          Mes réservations
        </CustomLi>
        <CustomLi
          onClick={goTo.bind(this, routes["USER.MY.HISTORY"].path)}
          className={`w-100 py-3 ${isCurrent(routes["USER.MY.HISTORY"].path) ? 'active' : ''}`}
        >
          Mon historique
        </CustomLi>
        <CustomLi
          onClick={goTo.bind(this, routes["PROFILE"].path)}
          className={`w-100 py-3 ${isCurrent(routes["PROFILE"].path) ? 'active' : ''}`}
        >
          Profil
        </CustomLi>
        <Pill
          value="Se déconnecter"
          className="border-20 px-4 mt-5"
          onClick={handleLogout}
        />
      </ul>
    </div>
  );
};

const CustomLi = styled.li`
  color: #2D3748;
  &.active {
    color: #29D2C3;
    font-weight: bold;
  }
`;

export default SideBar;