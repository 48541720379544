import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LayoutContext } from 'layouts/Layout';
import { Button, Pill } from "components";
import { FaTimes } from "react-icons/fa";

const ConfirmModal = props => {
  const classes = useStyles();
  const { width, activeTheme: { colors } } = useContext(LayoutContext);

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-100 w-100 d-flex justify-content-center flex-column p-3">
        <h3 className="text-principal font-weight-bold mb-4">Confirmation</h3>
        <span
          className={`w-100 ${props.messageClassName || ""}`}
          style={{
            ...props.messageStyle
          }}
        >{props.message}</span>
        <div className="d-flex align-items-center justify-content-end mt-5 w-100">
          <Pill
            className={`mr-3 px-5 border-5 ${props.cancelClassName || ""}`}
            onClick={props.onHide.bind(this, false)}
          >
            {props.cancelLabel}
          </Pill>
          <Button
            className={`px-5 border-5 ${props.successClassName}`}
            variant="principal"
            onClick={props.onHide.bind(this, true)}
          >
            {props.successLabel}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  successLabel: PropTypes.string,
  successStyle: PropTypes.object,
  successClassName: PropTypes.string,
  cancelStyle: PropTypes.object,
  cancelLabel: PropTypes.string,
  cancelClassName: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  messageClassName: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmModal.defaultProps = {
  visible: false,
  message: "Voulez-vous vraiment continuer ?",
  successLabel: "Accept",
  cancelLabel: "Decline",
  cancelStyle: {},
  successStyle: {},
  messageStyle: {},
  onHide: () => console.log("Please attach an onHide function to [ConfirmModal]"),
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    borderWidth: 0,
    width: "100%",
    padding: "20px"
  },
}));

const CloseIcon = styled(FaTimes)`
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 20px;
  color: #CBD5E0;
  cursor: pointer;
`;


export default ConfirmModal;