import React from "react";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import PropTypes from 'prop-types';
import "./styles.scss";

const Checkbox = props => {
  const handleClick = () => {
    props.onChange(!props.checked);
  };

  return props.checked === true ?
    <MdOutlineCheckBox
      style={props.style ? props.style : {}}
      onClick={handleClick}
      size={props.size}
      className={`chs-checkbox-${props.variant ? props.variant : 'principal'} ${props.className ? props.className : ''}`}
    />
    :
    <MdOutlineCheckBoxOutlineBlank
      style={props.style ? props.style : {}}
      onClick={handleClick}
      size={props.size}
      className={`chs-checkbox-${props.variant ? props.variant : 'principal'} disabled ${props.className ? props.className : ''}`}
    />;
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  size: PropTypes.number,
  variant: PropTypes.oneOf(["principal", "green", "infos", "red", "blue", "black", "gray"]),
};

Checkbox.defaultProps = {
  onChange: () => console.log("Please attach an onHide function to [Checkbox]"),
  checked: false,
  className: ""
};

export default Checkbox;