/**
 * Verify is the user has one of the required role
 * @param {Array} enabledRoles required roles
 * @param {String} userRole the role of the user
 * @returns {bool}
 */
export default (enabledRoles, userRole) => {
	if (enabledRoles.length === 0) {
		return true;
	}

	if (enabledRoles.includes(userRole)) {
		return true;
	}

	return false;
};