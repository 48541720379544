export const ORDER_STATUS = {
  NEW: "NEW",
  COMPLETED: "COMPLETED",
  PREPARING: "PREPARING",
  DELIVERING: "DELIVERING"
};

export const USER_STATUS = {
  ACTIVE: "Activé",
  INACTIVE: "Désactivé"
};

export const ACCESS_STATUS = {
  ACTIVE: "activé",
  INACTIVE: "désactivé"
};