import { removeCookie, setCookie } from 'services/cookie';
import { authMe } from "apis";
import { setupApp } from './app';

export const fakeAuth = currentUser => {
	return {
		type: AUTHENTICATE,
		user: currentUser
	};
};

export const AUTHENTICATE = 'AUTHENTICATE';
export const authenticate = (callback = () => { }) => {
	return async (dispatch, getState) => {
		await authMe().then(auth => {
			if (auth?.success) {
				dispatch({
					type: AUTHENTICATE,
					user: auth?.data
				});
				callback({
					success: true,
					message: "DONE"
				});
			} else {
				callback({
					success: false,
					message: auth?.message
				});
			}
		}).catch(error => {
			callback({
				success: false,
				message: error?.message
			});
		});
	}
};

export const loginUser = (loginResponse, toRemenber = true) => {
	return async (dispatch, getState) => {
		setCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY, loginResponse?.access_token);
		dispatch({
			type: AUTHENTICATE,
			user: loginResponse?.user
		});
		dispatch(authenticate());
		dispatch(setupApp());
	}
};

export const LOGOUT = 'LOGOUT';
export const logout = (callback = () => { }) => {
	return async (dispatch, getState) => {
		const remove = removeCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY);
		if (remove.success) {
			dispatch({ type: LOGOUT });
			callback({
				success: true,
				message: "DONE"
			});
		} else {
			callback({
				success: false,
				message: remove.message
			});
		}
	}
};