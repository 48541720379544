import { USER_STATUS } from "./status";
import UserImg from 'assets/profile.png';

export const ADMIN_FONCTIONNEL_ROLE = 'AF';
export const ADMIN_RESTAURATEUR_ROLE = 'AR';
export const RESTAURATEUR_SIMPLE_ROLE = 'RS';
export const ADMIN_TECHNIQUE_ROLE = 'AT';
export const USER_ROLE = 'UG';

export const ADMIN_FONCTIONNEL_FAKE = {
  name: "ADMIN",
  prenoms: "Fonctionnel",
  email: "fonctionnel@gmail.com",
  phone: "97979797",
  roles: [ADMIN_FONCTIONNEL_ROLE]
};

export const ADMIN_RESTAURATEUR_FAKE = {
  name: "ADMIN",
  prenoms: "Restaurateur",
  email: "restaurateur@gmail.com",
  phone: "97979797",
  roles: [ADMIN_RESTAURATEUR_ROLE]
};

export const RESTAURATEUR_SIMPLE_FAKE = {
  name: "SIMPLE",
  prenoms: "Restaurateur",
  email: "restaurateur@gmail.com",
  phone: "97979797",
  roles: [RESTAURATEUR_SIMPLE_ROLE]
};

export const ADMIN_TECHNIQUE_FAKE = {
  name: "ADMIN",
  prenoms: "Technique",
  email: "technique@gmail.com",
  phone: "97979797",
  roles: [ADMIN_TECHNIQUE_ROLE]
};

export const USER_FAKE = {
  name: "USER",
  prenoms: "User",
  email: "user@gmail.com",
  phone: "97979797",
  roles: [USER_ROLE]
};

export const USERS_LIST_FAKE = [
  { photo: UserImg, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.ACTIVE },
  { photo: null, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.INACTIVE },
  { photo: null, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.INACTIVE },
  { photo: UserImg, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.ACTIVE },
  { photo: UserImg, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.ACTIVE },
  { photo: null, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.INACTIVE },
  { photo: UserImg, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.ACTIVE },
  { photo: UserImg, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.ACTIVE },
  { photo: null, matricule: "00000000", email: "hello@gmail.com", fullName: "Esthera Jackson", fonctions: "Manager", categories: "Alexa Liras", motif: "Aliquet eget in....", periode: "14 - 28 Jan 2022", structure: "Interne", repas: 2, status: USER_STATUS.ACTIVE },
];

export const DUMMY_CATEGORIES = [
  { id: 1, title: "Categorie 1", slug: "category-1" },
  { id: 2, title: "Categorie 2", slug: "category-2" },
  { id: 3, title: "Categorie 3", slug: "category-3" },
];

export const DUMMY_ACCESS_STATUS = [
  { id: 1, libelle: "Activé", value: 1 },
  { id: 2, libelle: "Désactivé", value: 0 },
];

export const DUMMY_RESERVATIONS_STATUS = [
  { id: 1, libelle: "Réservé" },
  { id: 2, libelle: "Annulé" },
];

export const TYPES_USAGERS_CHART_OPTIONS = {
  animations: {
    enabled: false
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    type: "donut",
    sparkline: {
      enabled: false
    }
  },
  colors: ["#F9C22D", "#146D65"],
  stroke: {
    show: false,
  },
  legend: {
    fontFamily: "Nunito"
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            fontSize: "12px",
            fontFamily: "Nunito",
          },
          value: {
            fontSize: "13px",
            fontWeight: "bold",
            fontFamily: "Nunito",
            formatter: value => `${value} %`
          }
        }
      }
    }
  },
  labels: ["Salariés", "Visiteurs"],
};

export const CATEGORIES_CHART_OPTIONS = {
  animations: {
    enabled: false
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    type: "donut",
    sparkline: {
      enabled: false
    }
  },
  colors: ["#34FBE8", "#146D65", "#21A69A", "#F9C22D"],
  stroke: {
    show: false,
  },
  legend: {
    fontFamily: "Nunito"
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
            fontSize: "12px",
            fontFamily: "Nunito",
          },
          value: {
            fontSize: "13px",
            fontWeight: "bold",
            fontFamily: "Nunito",
            formatter: value => `${value} %`
          }
        }
      }
    }
  },
  labels: ["Exécutants", "Techniciens", "Ouvriers", "Cadres"],
};

export const STATS_CHART_OPTIONS = {
  animations: {
    enabled: false
  },
  tooltip: {
    enabled: false,
  },
  chart: {
    id: "basic-bar",
  },
  colors: ["#F9C22D", "#146D65"],
  stroke: {
    show: false,
  },
  legend: {
    fontFamily: "Nunito"
  },
};

export const YEARS_LIST = () => {
  let output = [];
  for (let year = 2022; year <= (new Date()).getFullYear() + 3; year++) {
    output.push({
      label: String(year),
      value: year,
    });
  }
  return output;
};

export const MONTHS_LIST = [
  { value: 1, label: "Janvier" },
  { value: 2, label: "Février" },
  { value: 3, label: "Mars" },
  { value: 4, label: "Avril" },
  { value: 5, label: "Mai" },
  { value: 6, label: "Juin" },
  { value: 7, label: "Juillet" },
  { value: 8, label: "Août" },
  { value: 9, label: "Septembre" },
  { value: 10, label: "Octobre" },
  { value: 11, label: "Novembre" },
  { value: 12, label: "Décembre" },
];

export const DUMMY_MENSUELLE_STATS = [
  { date: "01/07/2022", qty: 300, price: 1000, montant: 270000 },
  { date: "02/07/2022", qty: 300, price: 1000, montant: 90000 },
  { date: "03/07/2022", qty: 300, price: 1000, montant: 100000 },
  { date: "04/07/2022", qty: 300, price: 1000, montant: 70000 },
  { date: "05/07/2022", qty: 300, price: 1000, montant: 500000 },
  { date: "06/07/2022", qty: 300, price: 1000, montant: 800000 },
  { date: "07/07/2022", qty: 300, price: 1000, montant: 960000 },
  { date: "08/07/2022", qty: 300, price: 1000, montant: 280000 },
  { date: "09/07/2022", qty: 300, price: 1000, montant: 440000 },
  { date: "10/07/2022", qty: 300, price: 1000, montant: 300000 },
  { date: "11/07/2022", qty: 300, price: 1000, montant: 300000 },
  { date: "12/07/2022", qty: 300, price: 1000, montant: 320000 },
  { date: "13/07/2022", qty: 300, price: 1000, montant: 300000 },
  { date: "14/07/2022", qty: 300, price: 1000, montant: 58000 },
  { date: "15/07/2022", qty: 300, price: 1000, montant: 340000 },
  { date: "16/07/2022", qty: 300, price: 1000, montant: 950000 },
  { date: "17/07/2022", qty: 300, price: 1000, montant: 760000 },
  { date: "18/07/2022", qty: 300, price: 1000, montant: 350000 },
  { date: "19/07/2022", qty: 30, price: 1000, montant: 140000 },
  { date: "20/07/2022", qty: 30, price: 1000, montant: 300000 },
  { date: "21/07/2022", qty: 150, price: 1000, montant: 35000 },
  { date: "22/07/2022", qty: 150, price: 1000, montant: 250000 },
  { date: "23/07/2022", qty: 150, price: 1000, montant: 200000 },
  { date: "24/07/2022", qty: 150, price: 1000, montant: 900000 },
  { date: "25/07/2022", qty: 250, price: 1000, montant: 300000 },
  { date: "26/07/2022", qty: 150, price: 1000, montant: 300000 },
  { date: "27/07/2022", qty: 150, price: 1000, montant: 70000 },
  { date: "28/07/2022", qty: 200, price: 1000, montant: 900000 },
  { date: "29/07/2022", qty: 200, price: 1000, montant: 500000 },
  { date: "30/07/2022", qty: 200, price: 1000, montant: 200000 },
];