import React from 'react';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';
import styled from 'styled-components';


const LongArrow = ({
	width,
	height,
	color,
	isRight,
	wrapperStyle,
	wrapperClassName
}) => {
	return (
		<div
			style={{ ...wrapperStyle }}
			className={`d-flex align-items-center position-relative ${wrapperClassName || ""}`}
		>
			{!isRight && <AiOutlineLeft size={20} color={color || "black"} style={{ transform: "translateX(13px)" }} />}
			<Line height={height} width={width} color={color} />
			{isRight && <AiOutlineRight size={20} color={color || "black"} style={{ transform: "translateX(-13px)" }} />}
		</div>
	)
};

const Line = styled.div`
	height: ${({ height }) => height || "1px"};
	width: ${({ width }) => width || "100px"};
	background-color: ${({ color }) => color || "black"};
	border-radius: 10px;
`;

export default LongArrow;