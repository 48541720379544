import { CHART_COLORS } from "configs";
import shuffle from "./shuffle";

/**
 * Capitalize a texte
 * @param {String} texte Texte to capitalize
 * @returns String capitalized
 */
export const capitalize = texte => {
  if (!!texte) {
    return String(texte).charAt(0).toUpperCase() + String(texte).slice(1);
  } else {
    return "";
  }
};

export const initiauxOf = (name: string) => {
  return String(name).split(' ').slice(0, 2).map(el => String(el).charAt(0)).join('').toUpperCase();
};

export const getChartOptions = (elements: Array, labelKey: string, total: number) => {
  return {
    animations: {
      enabled: false
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      type: "donut",
      sparkline: {
        enabled: false
      }
    },
    colors: shuffle(CHART_COLORS).slice(0, elements.length),
    stroke: {
      show: false,
    },
    legend: {
      fontFamily: "Nunito"
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "12px",
              fontFamily: "Nunito",
            },
            value: {
              fontSize: "13px",
              fontWeight: "bold",
              fontFamily: "Nunito",
              formatter: value => `${value} %`
            }
          }
        }
      }
    },
    labels: elements?.map(el => el[labelKey]),
  }
};


export const getStatsChartOptions = (elements: Array, labelFormatter: (el: any) => string) => {
  return {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    colors: ['#de8244'],
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: elements?.map(labelFormatter),
      labels: {
        show: true,
        rotate: -90,
        style: {
          fontSize: '12px',
          fontFamily: 'Nunito',
          cssClass: 'apexcharts-xaxis-label',
        },
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      fontFamily: "Nunito"
    },
  }
};