import request from './request';

export const getAppData = () => {
  return request({
    url: 'getDatas',
    method: 'GET'
  });
};

// STRUCTURES
export const getStructures = () => {
  return request({
    url: 'getStructure',
    method: 'GET'
  });
};
export const createStructure = data => {
  return request({
    url: 'createStructure',
    method: 'POST',
    data
  });
};
export const deleteStructure = (id) => {
  return request({
    url: `deleteStructure/${id}`,
    method: 'DELETE'
  });
};

// CATEGORIES
export const getCategories = () => {
  return request({
    url: 'getCategorie',
    method: 'GET'
  });
};
export const createCategorie = data => {
  return request({
    url: 'createCategorie',
    method: 'POST',
    data
  });
};
export const deleteCategorie = (id) => {
  return request({
    url: `deleteCategorie/${id}`,
    method: 'DELETE'
  });
};

// TYPES USAGERS
export const getTypesUsagers = () => {
  return request({
    url: 'getType',
    method: 'GET'
  });
};
export const createTypeUsager = data => {
  return request({
    url: 'createType',
    method: 'POST',
    data
  });
};
export const deleteTypeUsager = (id) => {
  return request({
    url: `deleteType/${id}`,
    method: 'DELETE'
  });
};

// POSTES
export const getPostes = () => {
  return request({
    url: 'getPost',
    method: 'GET'
  });
};
export const createPoste = data => {
  return request({
    url: 'createPost',
    method: 'POST',
    data
  });
};
export const deletePoste = (id) => {
  return request({
    url: `deletePost/${id}`,
    method: 'DELETE'
  });
};

// COMPTOIRS
export const getComptoirs = () => {
  return request({
    url: 'getComptoir',
    method: 'GET'
  });
};
export const createComptoir = data => {
  return request({
    url: 'createComptoir',
    method: 'POST',
    data
  });
};
export const deleteComptoir = (id) => {
  return request({
    url: `deleteComptoir/${id}`,
    method: 'DELETE'
  });
};

// TYPES REPAS
export const getTypesRepas = (search = "") => {
  return request({
    url: `getTypeRepas?search=${search}`,
    method: 'GET'
  });
};
export const createTypeRepas = data => {
  return request({
    url: 'createTypeRepas',
    method: 'POST',
    data
  });
};
export const deleteTypeRepas = (id) => {
  return request({
    url: `deleteTypeRepas/${id}`,
    method: 'DELETE'
  });
};

// ELEMENTS REPAS
export const getElementsRepas = (search = "") => {
  return request({
    url: `getElementRepas?search=${search}`,
    method: 'GET'
  });
};
export const createElementRepas = data => {
  return request({
    url: 'createElementRepas',
    method: 'POST',
    data
  });
};
export const deleteElementRepas = (id) => {
  return request({
    url: `deleteElementRepas/${id}`,
    method: 'DELETE'
  });
};

// TYPES ABSENCES
export const getTypesAbsences = () => {
  return request({
    url: 'getTypeAbsence',
    method: 'GET'
  });
};

export const createTypeAbsence = data => {
  return request({
    url: 'createTypeAbsence',
    method: 'POST',
    data
  });
};

export const deleteTypeAbsence = (id) => {
  return request({
    url: `deleteTypeAbsence/${id}`,
    method: 'DELETE'
  });
};


// TYPES MENUS
export const getTypesMenus = () => {
  return request({
    url: 'getTypeMenu',
    method: 'GET'
  });
};
export const createTypeMenu = data => {
  return request({
    url: 'createTypeMenu',
    method: 'POST',
    data
  });
};
export const deleteTypeMenu = (id) => {
  return request({
    url: `deleteTypeMenu/${id}`,
    method: 'DELETE'
  });
};


// SERVICES
export const getServices = () => {
  return request({
    url: 'getService',
    method: 'GET'
  });
};
export const createService = data => {
  return request({
    url: 'createService',
    method: 'POST',
    data
  });
};
export const deleteService = (id) => {
  return request({
    url: `deleteService/${id}`,
    method: 'DELETE'
  });
};

export const storeSettings = data => {
  return request({
    url: 'storeSettings',
    method: 'POST',
    data,
  });
};