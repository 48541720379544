import { getAppData, storeSettings } from "apis";

export const SETUP_APP = 'SETUP_APP';
export const setupApp = (callback = () => { }) => {
  return async (dispatch, getState) => {
    await getAppData().then(response => {
      if (response?.success) {
        dispatch({
          type: SETUP_APP,
          payload: {
            types: response?.data?.Types,
            postes: response?.data?.Postes,
            structures: response?.data?.Structure,
            categories: response?.data?.Categorie,
            roles: response?.data?.Roles,
            typesAbsences: response?.data?.typeAbsence,
            comptoires: response?.data?.allComptoir,
            typesMenus: response?.data?.typeMenu,
            services: response?.data?.services,
            settings: response?.data?.settings,
          }
        });
        callback({
          success: true,
          message: "DONE"
        });
      } else {
        callback({
          success: false,
          message: response?.message
        });
      }
    }).catch(error => {
      callback({
        success: false,
        message: error?.message
      });
    });
  }
};

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = (updated, callback) => {
  return async (dispatch, getState) => {
    await storeSettings(updated).then(response => {
      if (response?.success) {
        dispatch({
          type: UPDATE_SETTINGS,
          settings: response?.data,
        });
        callback({
          success: true,
          message: "DONE"
        });
      } else {
        callback({
          success: false,
          message: response?.message
        });
      }
    }).catch(error => {
      callback({
        success: false,
        message: error?.message
      });
    });
  }
};

export const CLEAR_APP_DATA = 'CLEAR_APP_DATA';
export const clearAppData = () => {
  return { type: CLEAR_APP_DATA };
};