import React, { useState } from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Button } from 'components';
import { BsThreeDots } from "react-icons/bs";
import { Popover, makeStyles } from "@material-ui/core";

const StatistiqueCard = props => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [showPop, setShowPop] = useState(Boolean(anchorElement));

  const handleOpenPopover = (event) => {
    setAnchorElement(event.currentTarget);
    setShowPop(true);
  };

  const handleClosePopover = () => {
    setAnchorElement(null);
    setShowPop(false);
  };

  return (
    <Content className="py-3 pr-3 h-100">
      <OptionsPopover
        visible={showPop}
        anchor={anchorElement}
        onHide={handleClosePopover}
        render={props.renderOptions}
      />
      <Button style={{ height: "50px", width: "50px", backgroundColor: props.color }}>
        {props.icon}
      </Button>
      <div className="d-flex flex-column justify-content-center px-4">
        <Title>
          {props.title}
        </Title>
        <Value>
          {props.value}
        </Value>
      </div>
      {props.hasOptions && <Dots onClick={handleOpenPopover} />}
    </Content>
  );
};

const OptionsPopover = props => {
  const classes = useStyles();

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      {props.render}
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: '200px',
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "-2px -1px 10px 2px rgba(192, 197, 223, 0.2)"
  }
}));


StatistiqueCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.any.isRequired,
  hasOptions: PropTypes.bool,
  renderOptions: PropTypes.any
};

StatistiqueCard.defaultProps = {
  color: "black",
  hasOptions: false,
  renderOptions: null
};

const Content = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const Title = styled.span`
  font-size: 90%;
`;

const Value = styled.span`
  font-size: 25px;
  margin-top: 15px;
`;

const Dots = styled(BsThreeDots)`
  position: absolute;
  z-index: 5;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 25px;
  color: #7C809E;
`;

export default StatistiqueCard;