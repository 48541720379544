import React, { createContext, useState } from 'react';
import validate, { resetObject } from './validate';
import SwiftText from './SwiftText';
import SwiftTextarea from './SwiftTextarea';
import SwiftInput from './SwiftInput';
import SwiftSelect from './SwiftSelect';
import PropTypes from 'prop-types';

export interface SubmitResponse {
	isValid: boolean;
	fields: object;
	clearForm: () => void;
};

const SwiftContext = createContext({});

const SwiftForm = ({
	children,
	rules,
	customRules,
	fields,
	errors,
	lang,
	handleSubmit,
	...rest
}) => {
	const [form, setForm] = useState({ fields, errors });

	const submit = () => {
		const validation = validate(form.fields, rules, lang);
		if (validation.isFormValid()) {
			setForm({
				fields: form.fields,
				errors: resetObject(form.errors)
			});
			handleSubmit({ isValid: true, fields: form.fields, clearForm: () => setForm({ fields: resetObject(form.fields), errors: resetObject(form.errors) }) });
		} else {
			const { response } = validation;
			let errors = {};
			for (const key in response) {
				if (Object.hasOwnProperty.call(response, key)) {
					const field = response[key];
					errors = {
						...errors,
						[key]: field.error
					}
				}
			}
			setForm({
				fields: form.fields,
				errors
			});
			handleSubmit({ isValid: false, fields: form.fields });
		}
	};

	const handleInputChange = event => {
		if (!!event?.target?.name) {
			const fieldValidation = validate(
				{ [event.target.name]: event.target.value },
				{ [event.target.name]: rules[event.target.name] },
				lang
			);
			setForm(old => ({
				fields: {
					...old.fields,
					[event.target.name]: event.target.value
				},
				errors: {
					...old.errors,
					[event.target.name]: fieldValidation.response[event.target.name].error
				}
			}));
		}
	};

	const handleManyInputsChange = (data: { name: string, value: string }[]) => {
		for (let i = 0; i < data.length; i++) {
			const field = data[i];

			if (!!field?.name) {
				const fieldValidation = validate(
					{ [field.name]: field.value },
					{ [field.name]: rules[field.name] },
					lang
				);
				setForm(old => ({
					fields: {
						...old.fields,
						[field.name]: field.value
					},
					errors: {
						...old.errors,
						[field.name]: fieldValidation.response[field.name].error
					}
				}));
			}
		}
	};

	return (
		<SwiftContext.Provider
			value={{
				submit,
				handleInputChange,
				handleManyInputsChange,
				fields: form.fields,
				errors: form.errors
			}}
		>
			<SwiftContext.Consumer>
				{children}
			</SwiftContext.Consumer>
		</SwiftContext.Provider>
	);
};

SwiftForm.propTypes = {
	fields: PropTypes.object,
	rules: PropTypes.object,
	errors: PropTypes.object,
	lang: PropTypes.oneOf(["fr", "en"]),
	handleSubmit: PropTypes.func,
};

SwiftForm.defaultProps = {
	fields: {},
	rules: {},
	lang: "fr",
	errors: {},
	handleSubmit: () => console.log("Please attach a handleSubmit method. This method is provided and get the submit callback response."),
};

export {
	SwiftText,
	SwiftTextarea,
	SwiftInput,
	SwiftSelect,
	validate,
};

export default SwiftForm;