import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from 'layouts/Layout';
import UserImg from 'assets/no_user.png';
import { AiOutlineMenu } from 'react-icons/ai';

import { logout, useAuth } from 'contexts/redux';
import { Pill, Button } from 'components';
import { FaAngleDown } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { routes } from 'configs';

import { ReactComponent as BellsIcon } from 'assets/icons/bells.svg';
import { initiauxOf } from 'helpers';

const AdminNavbar = ({
  toggleSidebar,
  blurContent,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useAuth();

  const isCurrent = url => {
    return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <header style={blurContent ? { filter: "blur(0.5rem)" } : {}} className="chs-admin-navbar">
      <AiOutlineMenu size={30} className="chs-admin-navbar__brand" onClick={toggleSidebar} />
      <ul className="chs-admin-navbar-menu">
        <li className={`chs-admin-navbar-item`}>
          <div className="chs-admin-navbar-link" onClick={() => history.push(routes["PROFILE"].path)}>
            {
              !!currentUser?.photo ?
                <Image src={`${process.env.REACT_APP_STORAGE_URL}/${currentUser?.photo}`} />
                :
                <Button
                  value={initiauxOf(`${currentUser?.name} ${currentUser?.prenom}` || "")}
                  style={{ height: "40px", width: "40px" }}
                  className="border-5 font-weight-bold"
                />
            }
            <FaAngleDown
              size={20}
              className='ml-2 text-principal'
            />
          </div>
        </li>
        <li className={`chs-admin-navbar-item`}>
          <div className="chs-admin-navbar-link">
            <Pill
              value="Se déconnecter"
              className="border-20 px-4"
              onClick={handleLogout}
            />
          </div>
        </li>
      </ul>
    </header>
  );
};

const Image = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 5px;
`;

export default AdminNavbar;