import request from './request';

export const authMe = () => {
	return request({
		url: 'me',
		method: 'GET'
	});
};

export const login = (data) => {
	return request({
		url: 'login',
		method: 'POST',
		data
	});
};

export const updateMyData = (data) => {
	return request({
		url: `updateUser`,
		method: 'POST',
		data
	});
};

export const updatePassword = (data) => {
	return request({
		url: `changePassword`,
		method: 'POST',
		data
	});
};

export const uploadProfilePicture = (data, handleProgress = () => { }) => {
	return request({
		url: `addUserProfil`,
		method: 'POST',
		timeout: null,
		data,
		onUploadProgress: handleProgress
	});
};

export const initiateResetPassword = (data) => {
	return request({
		url: `resetPasswordMail`,
		method: 'POST',
		data
	});
};

export const updateResetPassword = (data, token) => {
	return request({
		url: `resetPassword`,
		method: 'POST',
		data,
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		}
	});
};