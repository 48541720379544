import React from "react";
import AdminNavbar from './components/AdminNavbar';
import AdminSidebar from './components/AdminSidebar';
import classnames from 'classnames';
import "./styles.scss";
import styled from "styled-components";

const Admin = ({ children, blurContent, ...rest }) => {
	const [showSidebar, setShowSidebar] = React.useState(false);

	React.useEffect(() => {
		if (window !== "undefined") {
			window.addEventListener("resize", addResizeListeners());
		}
	}, []);

	const addResizeListeners = () => {
		const width = window.innerWidth;
		if (width > 750) {
			setShowSidebar(true);
		}
	}

	const toggleShowSidebar = () => {
		setShowSidebar(!showSidebar);
	};

	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				display: "flex",
				alignItems: "center",
				overflow: "hidden",
				backgroundColor: '#FAFCFF'
			}}
			className="p-4"
		>
			<AdminSidebar
				visible={showSidebar}
				handleToggle={toggleShowSidebar}
				blurContent={blurContent}
			/>
			<Wrapper>
				<AdminNavbar
					toggleSidebar={toggleShowSidebar}
					blurContent={blurContent}
				/>
				<main className={classnames("chs-admin-main mt-4")}>
					{children}
				</main>
			</Wrapper>
		</div>
	);
};

const Wrapper = styled.div`
	display: flex;
	width: calc(100% - 300px);
	height: 100%;
	flex-direction: column;
	align-items: center;
	padding-left: 20px;
	@media only screen and (max-width: 768px) {
		padding-left: 0px;
	}
`;

export default Admin;