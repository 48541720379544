import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, useAuth } from 'contexts/redux';
import { routes } from 'configs';
import { Button, Pill } from 'components';
import { initiauxOf } from 'helpers';
import { FaAngleDown } from 'react-icons/fa';


const Navbar = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useAuth();

  const isCurrent = url => {
    return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="d-none d-lg-block">
      <header className="header mb-4">
        <nav className="chs-navbar">
          <ul className="chs-nav-menu">
            <li className={`chs-nav-item ${isCurrent(routes["ADMIN.DASHBOARD"].path) ? 'active' : ''}`}>
              <Link to={routes["ADMIN.DASHBOARD"].path} className="chs-nav-link flex-column">
                Menu
                <Dot
                  background={isCurrent(routes["ADMIN.DASHBOARD"].path) ? "#1F9D92" : "white"}
                />
              </Link>
            </li>
            <li className={`chs-nav-item ${isCurrent(routes["USER.MY.RESERVATIONS"].path) ? 'active' : ''}`}>
              <Link to={routes["USER.MY.RESERVATIONS"].path} className="chs-nav-link flex-column">
                Mes réservations
                <Dot
                  background={isCurrent(routes["USER.MY.RESERVATIONS"].path) ? "#1F9D92" : "white"}
                />
              </Link>
            </li>
            <li className={`chs-nav-item ${isCurrent(routes["USER.MY.HISTORY"].path) ? 'active' : ''}`}>
              <Link to={routes["USER.MY.HISTORY"].path} className="chs-nav-link flex-column">
                Mon historique
                <Dot
                  background={isCurrent(routes["USER.MY.HISTORY"].path) ? "#1F9D92" : "white"}
                />
              </Link>
            </li>
          </ul>
          <div className="d-flex align-items-center px-4 h-100">
            <div onClick={() => history.push(routes["PROFILE"].path)}>
              {
                !!currentUser?.photo ?
                  <Image src={`${process.env.REACT_APP_STORAGE_URL}/${currentUser?.photo}`} />
                  :
                  <Button
                    value={initiauxOf(currentUser?.name || "")}
                    style={{ height: "40px", width: "40px" }}
                    className="border-5 font-weight-bold"
                  />
              }
              <FaAngleDown
                size={20}
                className='ml-2 text-principal'
              />
            </div>
            <Pill
              value="Se déconnecter"
              className="border-20 px-4 ml-4"
              onClick={handleLogout}
            />
          </div>
        </nav>
      </header>
    </div >
  );
};

const Image = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
`;

const Dot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 4px;
  background-color: ${({ background }) => background || "#1F9D92"};
  margin-top: 5px;
`;

export default Navbar;