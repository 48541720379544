import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';

const NewsletterInput = ({
  type,
  name,
  label,
  value,
  error,
  onChange,
  iconRight,
  iconLeft,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  wrapperClassName,
  wrapperStyle,
  ...rest
}) => {
  return (
    <div style={{ width: "100%", ...wrapperStyle }} className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}>
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div className={`chs-input type-search ${containerClassName}`}>
        {iconLeft &&
          <div className="chs-input__left-icon">
            {iconLeft}
          </div>
        }
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          {...rest}
          className={`chs-input__field ${className} ${iconLeft ? 'ml-0' : ''} ${iconRight ? 'mr-0' : ''}`}
        />
        {iconRight &&
          <div className="chs-input__right-icon">
            {iconRight}
          </div>
        }
      </div>
      {!!error && <div className={`chs-input__feedback ${errorClassName}`}>{error}</div>}
    </div>
  );
};

NewsletterInput.propTypes = {
  type: PropTypes.oneOf(["text", "date", "time", "number", "password", "email", "tel", "datetime-local"]),
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  iconRight: PropTypes.any,
  iconLeft: PropTypes.any,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  placeholder: PropTypes.string
};

NewsletterInput.defaultProps = {
  type: 'email',
  name: '',
  value: '',
  onChange: () => console.log('no onChange method attached to NewsletterInput...'),
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
  wrapperClassName: "",
  wrapperStyle: {}
};

export default NewsletterInput;