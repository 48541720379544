import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Input, Select } from 'components';

interface TableProps {
  noPagination?: boolean;
  pages?: number;
  perPage?: number;
  handlePaginationChange?: (page: number, perPage: number) => void;
  onPerPageChange?: (size) => void;
  component?: React.ReactNode;
  children?: any;
};

const Table = (props: TableProps) => {
  const TableNode = props.component || TableContainer;

  const [perPage, setPerPage] = useState(props.perPage);

  const handlePerPageChange = e => {
    setPerPage(e?.target?.value);
    props.onPerPageChange(e?.target?.value);
  };

  useEffect(() => {
    setPerPage(props.perPage);
  }, [props.perPage]);

  return (
    <Wrapper {...props}>
      <TableNode>
        {props.children}
      </TableNode>
      {!props.noPagination &&
        <div className="w-100 d-flex align-items-center justify-content-between my-4">
          <div className="d-flex align-items-center">
            <Select
              containerStyle={{
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                height: "35px",
                width: "70px",
                borderColor: "#187269"
              }}
              wrapperClassName="w-auto"
              className="cursor-pointer"
              style={{ color: "#187269" }}
              value={String(perPage)}
              onChange={handlePerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
            </Select>
            <Input
              value={"par page"}
              readOnly
              containerStyle={{
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
                height: "35px",
                width: "100px",
                borderColor: "#187269"
              }}
              style={{ color: "#187269" }}
              containerClassName="border-left-0"
            />
          </div>
          <Pagination
            onChange={(e, page) => props.handlePaginationChange(page, perPage)}
            count={props.pages}
            variant="outlined"
            shape="rounded"
            color="standard"
            renderItem={(params) => <PaginationItem {...params} style={{ borderColor: "#187269" }} />}
          />
        </div>
      }
    </Wrapper>
  )
};

Table.defaultProps = {
  noPagination: false,
  pages: 1,
  perPage: 10,
  handlePaginationChange: () => console.log("No pagination handling function"),
  onPerPageChange: () => { }
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
`;

const TableContainer = styled.table`
  width: 100%;
  position: relative;
  border: 0px solid #E8357955;
  border-collapse: collapse;
  border-radius: 5px;
  tr {
    &.active {
      background-color: #29D2C3 !important;
      color: white;
    }
    &:hover {
      background-color: #29D2C30A;
    }
  }
  th {
    border-top: 0px solid #0002;
    border-bottom: 1px solid #0002;
    border-radius: 3px;
    padding: 15px 5px;
    font-weight: bold;
    background-color: #EEFFFE;
  }
  td {
    font-weight: 400;
    padding: 15px 5px;
    border: 0px solid #E8357955;
    border-collapse: collapse;
  }
`;

export default Table;