import React from "react";
import { makeStyles } from "@material-ui/core";
import { Spinner } from 'react-bootstrap';

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    height: '100vh'
  },
}));

function SpinnerLoader(props) {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ ...props.containerStyle }}>
      {
        props.component
          ?
          <props.component color={!!props.color ? props.color : '#666666'} {...props} />
          :
          <Spinner animation="border" variant='danger' />
      }
    </div>
  );
}

export default SpinnerLoader;