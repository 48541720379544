import React, { useState } from "react";
import classnames from "classnames";
import styled from "styled-components";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useAuth } from "contexts/redux";
import { useDispatch } from 'react-redux';

import { routes } from "configs";
import { AiOutlineDashboard, AiOutlineHistory, AiOutlineSetting } from "react-icons/ai";
import { FaList, FaRegChartBar, FaRegListAlt, FaTimes } from "react-icons/fa";

import { ReactComponent as SaladIcon } from 'assets/icons/salad.svg';
import { ReactComponent as AccountIcon } from 'assets/icons/user-check.svg';
import { ReactComponent as ReservationsIcon } from 'assets/icons/bring-forward.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { isEnabled } from "helpers";
import { IoFastFoodOutline } from "react-icons/io5";

const AdminSideBar = ({
  visible,
  handleToggle,
  blurContent,
  ...props
}) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useAuth();

  const isCurrent = url => {
    return url !== '/' ? location && location.pathname.includes(url) : location && location.pathname === url;
  };

  return (
    <aside
      style={blurContent ? { filter: "blur(0.5rem)" } : {}}
      className={classnames("chs-admin-sidebar ", visible ? "active" : "")}
    >
      <FaTimes
        style={{
          position: 'absolute',
          top: "15px",
          right: "15px",
        }}
        size={20}
        onClick={handleToggle}
        className="chs-admin-sidebar__brand"
      />
      <a className="chs-nav-logo" href={routes["ADMIN.DASHBOARD"].path}>
        <h3 className="font-weight-bold text-principal my-5">DigiCantine</h3>
      </a>
      <div className="chs-admin-sidebar-menu">
        {
          isEnabled(routes["ADMIN.DASHBOARD"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.DASHBOARD"].path)}
            className={`chs-admin-sidebar-item ${(isCurrent(routes["ADMIN.DASHBOARD"].path) || isCurrent(routes["PROFILE"].path)) ? 'active' : ''}`}
          >
            <AiOutlineDashboard size={20} />
            <span className="ml-3">Dashboard</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.GEST.USERS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.GEST.USERS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.GEST.USERS"].path) ? 'active' : ''}`}
          >
            <FaRegListAlt size={20} />
            <span className="ml-3">Gestions des usagers</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.TECHNIQUE.GEST.COMPTES"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.TECHNIQUE.GEST.COMPTES"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.TECHNIQUE.GEST.COMPTES"].path) ? 'active' : ''}`}
          >
            <AccountIcon size={20} fill={isCurrent(routes["ADMIN.TECHNIQUE.GEST.COMPTES"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Gestions des comptes</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.TECHNIQUE.HISTORIQUES"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.TECHNIQUE.HISTORIQUES"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.TECHNIQUE.HISTORIQUES"].path) ? 'active' : ''}`}
          >
            <FaList size={20} fill={isCurrent(routes["ADMIN.TECHNIQUE.HISTORIQUES"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Historiques des validations</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.RESTAURATEUR.MENU"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.RESTAURATEUR.MENU"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.RESTAURATEUR.MENU"].path) ? 'active' : ''}`}
          >
            <SaladIcon size={20} fill={isCurrent(routes["ADMIN.RESTAURATEUR.MENU"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Menu restaurant</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.RESTAURATEUR.RESERVATIONS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.RESTAURATEUR.RESERVATIONS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.RESTAURATEUR.RESERVATIONS"].path) ? 'active' : ''}`}
          >
            <ReservationsIcon size={20} fill={isCurrent(routes["ADMIN.RESTAURATEUR.RESERVATIONS"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Réservations</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.MY.RESERVATIONS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.MY.RESERVATIONS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.MY.RESERVATIONS"].path) ? 'active' : ''}`}
          >
            <IoFastFoodOutline size={20} color={isCurrent(routes["ADMIN.MY.RESERVATIONS"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Mes réservations</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.RESTAURATEUR.CANTINE"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.RESTAURATEUR.CANTINE"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.RESTAURATEUR.CANTINE"].path) ? 'active' : ''}`}
          >
            <IoFastFoodOutline size={20} color={isCurrent(routes["ADMIN.RESTAURATEUR.CANTINE"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Gestion de la cantine</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.TECHNIQUE.INFOS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.TECHNIQUE.INFOS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.TECHNIQUE.INFOS"].path) ? 'active' : ''}`}
          >
            <DashboardIcon size={20} fill={isCurrent(routes["ADMIN.TECHNIQUE.INFOS"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Informations</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.TECHNIQUE.SETTINGS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.TECHNIQUE.SETTINGS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.TECHNIQUE.SETTINGS"].path) ? 'active' : ''}`}
          >
            <AiOutlineSetting size={20} color={isCurrent(routes["ADMIN.TECHNIQUE.SETTINGS"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Paramètres</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.LOGS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.LOGS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.LOGS"].path) ? 'active' : ''}`}
          >
            <AiOutlineHistory size={20} color={isCurrent(routes["ADMIN.LOGS"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Logs Usager</span>
          </div>
        }
        {
          isEnabled(routes["ADMIN.STATS"].roles, currentUser?.codeRole) &&
          <div
            onClick={() => history.push(routes["ADMIN.STATS"].path)}
            className={`chs-admin-sidebar-item ${isCurrent(routes["ADMIN.STATS"].path) ? 'active' : ''}`}
          >
            <FaRegChartBar size={20} color={isCurrent(routes["ADMIN.STATS"].path) ? '#FFFFFF' : '#2D3748'} />
            <span className="ml-3">Statistiques</span>
          </div>
        }
      </div>
    </aside>
  );
};

export default AdminSideBar;