import axios from 'axios';
import { getCookie } from 'services/cookie';

const service = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	timeout: 20000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	},
});

service.interceptors.request.use(async config => {
	const accessToken = getCookie(process.env.REACT_APP_ACCESS_TOKEN_KEY);

	if (!!accessToken) {
		config.headers['Authorization'] = 'Bearer ' + accessToken;
	}

	return config;
}, error => {
	return Promise.reject(error);
});

service.interceptors.response.use(
	response => {
		return Promise.resolve(response.data);
	},
	error => {
		if (error?.response?.status === 422) {
			error.message = `Les champs de données sont incorrectement remplis`;
		} else if (error?.response?.status === 401) {
			error.message = "Vous n'êtes pas autorisés à continuer";
		} else {
			error.message = "Une erreur interne est survenue";
		}
		return Promise.reject(error);
	}
);

export default service;